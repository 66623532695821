.setpin-main{
    text-align: center;
    padding: 0 25px 70px;
    overflow: auto;
    h2{
      font-family: $poppins-500;
      font-size: 22px;
      line-height: 30px;
      color: #000;
      margin: 30px 0 10px;
    }
    p{
      font-family: $poppins-400;
      font-size: 14px;
      line-height: 22px;
      color: #929292;
    }
    .reenter-pin{
      font-family: $poppins-400;
      font-size: 14px;
      line-height: 22px;
      color: #e35136;
    }
    .btn-holder{
      background-color: #fff;
      padding: 20px 10px;
      position: fixed;
      z-index: 9;
      left: 0;
      bottom: 0;
      width: 100%;
      .btn-primary{
        max-width: 197px;
        width: 100%;
      }
    }
    .pincode-input-container{
      padding-right: 0 !important;
      padding-left: 0 !important;
      .pincode-input-text{
        width: 45px !important;
        font-size: 20px;
        color: #F38666;
        &:valid{
          border: #F38666;
        }
      }
    }
}