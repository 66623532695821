.profilebodyinsta {
  padding: 10px 40px 6px;
  text-align: center;
  display: inline-flex;
}

.instaimgholder-main {
  text-align: center;
  margin-left: -36px;
  margin-right: 5px;
}

.instaimgholder {
  border-radius: 50%;
  width: 100px;
  margin-left: 25px;
}

.btnprimaryinsta {
  background-color: #ef5e33 !important;
  box-shadow: none;
  border: none;
  border-radius: 6px;
  padding: 0 15px;
  text-align: center;
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: normal;
  box-shadow: none !important;
  color: #fff;
  background: black;
  color: white;
  width: 97px;
}

.navclassinsta {
  padding: 10px;
}

// masonry Code

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.2em 0.5em;
  justify-self: start;
}
//SINGLE COLUMN 
.masonrycontainer {


//double column
 /* margin: 0 8px;
  column-count: 2;
  column-gap: 10px;*/
  
   //SINGLE_COLUMN
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 8px;
  gap: 0px;
}
// masonry Code

.myclass {
  flex: 0 0 auto;

  width: 181px;
  height: 240px;
  margin: auto;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 4px;
  position: relative;
  text-align: center;
}

.imgholdergrid {
  height: 100%;
  width: 100%;
  border-radius: 10px;
    object-fit: cover;
}
.rowforalbums {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}


.myclasssinglealbum {
  width: auto;
  height: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.image-container {
/* 4:3 aspect ratio (e.g., 3/4 = 0.75) */
}

// .image-container img {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover; /* Maintain image aspect ratio and fill the container */
// }
.top-left {
  position: absolute;
  top: 8px;
  left: 7px;
  font-size: 16px;
  color: white;
  text-align: left;
}

.name {
  font-family: "fredoka_oneregular";
  // font-weight: bold;
  margin-top: 3px;
  margin-left: 15px;
  color: rgb(110, 110, 110);
  text-align: left;
}

.text {
  padding: 13px;
  // font-weight: bold;
  font-family: "fredoka_oneregular";
  font-size: x-large;
  color: black;
  margin-left: 15px; 
}

.label {
  font-family: "fredoka_oneregular";
  color: black;
  margin-left: 15px; 
  font-size: x-large;
  text-align: left;

}

.moodheader {
  //\position: fixed;
  font-family: "fredoka_oneregular";
  color: #ff007a !important;
  margin-left: 25px; 
  font-size: xx-large;
  //background-color: white;
  //width: 100%;

}

/*.footer {
  width: 66%;
  height: 11%;
  //background-color: black;
  color: white;
  text-align: center;
  margin-bottom: 23px;
  margin-left: 72px;
  border-radius: 10px;
  font-family: "fredoka_oneregular";

  #p {
    font-family: "fredoka_oneregular";
  }

  .button {
    background-color: #ff007a !important;
    box-shadow: none;
    border: none;
    border-radius: 3px;
    padding: 0 15px;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    font-weight: normal;
    margin-top: 16px;
    box-shadow: none !important;
    color: #fff;
   // background: rgba(0, 0, 0, 0.8);
    color: white;
    width: 62%;
  }
}*/


.footer {

  position: fixed;
  display: flex;
 // bottom: 0;
  left: 50%; /* Position the left edge at the center of the screen */
  transform: translateX(-50%); /* Center the footer horizontally */

  width: 95%;
  height: 180px;
 //line-height: 120px;
  color: white;
  text-align: center;
 // margin-bottom: 23px;
  margin: 10px auto; /* This centers the entire footer horizontally */
  //margin-left: 15px;
  border-radius: 8px;
  font-family: "fredoka_oneregular";


  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;

  .footer-paragraph {
    font-family: "Helvetica Neue";
    font-size: 16px; /* Adjust the font size */
    margin: 0; /* Adjust the margins (top and bottom) */
    text-align: left;
    line-height: 1.4; /* Adjust the line spacing (line height) */
    width: 80%;
    padding-left: 20px; /* Add padding to move the paragraph to the right */
    padding-top: 5px;
  }

  footer-paragraph strong {
    font-family: "fredoka_oneregular";
}

.icon {
  display: block;
  width: 50px; /* Adjust the width of the icon */
  height: 50px; /* Adjust the height of the icon */
  //padding-top: 10px;
 // margin-left: 8px;
  margin-top: 5px;
 // margin: 0 auto 10px; /* Center the icon horizontally and add margin */
}

.button-label {
  font-family: "fredoka_oneregular";
  font-size: 16px; /* Adjust the font size */
}
  

  /* Button styling */
  .button {
    background-color: #ff007a !important;
    border: none;
    border-radius: 5px;
   // padding: 0 15px;
    font-size: 16px;
    line-height: 50px;
    font-weight: normal;
    color: white;
    width: 90%;

    position: absolute; /* Position the button */
    bottom: 20px; /* 20px from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    
    
    //margin-bottom: 16px;
    //left: 50%;
    //transform: translateX(-50%);
  }
}


.new-check {
  .row {
    display: -ms-flexbox; /* IE 10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE 10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }

  /* Create two equal columns that sits next to each other */
  .column {
    -ms-flex: 50%; /* IE 10 */
    flex: 50%;
    padding: 0 4px;
  }

  .column img {
    margin-top: 8px;
    vertical-align: middle;
  }
}

.img-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.img-wrapper > div {
  flex: 0 1 33%;
}
