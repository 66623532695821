.section {
    $block: &;
    position: relative;
    padding: 40px 0;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 0;

        & ~ .container {
            position: relative;
            z-index: 20;
        }

        &--fixed {
            background-attachment: fixed;
        }
    }

    &--white {
        background-color: #fff;
    }

    &--grey {
        background-color: #000;
    }

    &--blue {
        background-color: #000;
    }

    &--black {
        background-color: #000;
    }

    @include mobile() {
        padding: 30px 0;
    }

    &--no-padding-bottom {
        padding-bottom: 0;
    }
} // section
