html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}
body, html{
	// min-height: 100%;
	// height: 100%;
}

.wrapper{
	display: table;
	table-layout: fixed;
	height: 100%;
	width: 100%;
}

.content-box{
	background-color: #fff;
	-webkit-box-shadow: 0 0 49px 10px rgba(139, 139, 139, 0.34);
	-moz-box-shadow: 0 0 49px 10px rgba(139, 139, 139, 0.34);
	box-shadow: 0 0 49px 10px rgba(139, 139, 139, 0.34);
	@include radius(5px);
}
.overflow-hidden{
	overflow: hidden !important;
}
@include upto-tablet () {
  
}